<template>
  <AppProvider>
    <router-view/>
  </AppProvider>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

</style>
