
export const routesConfig = [
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import('./views/auth/ResetPasswordPage.vue')
    },

    /**
     * ADMIN DASHBOARD
     */

    {
        path: "/admin",
        name: "admin",
        component: () => import('./views/admin/DashboardPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/login",
        name: "admin-login",
        component: () => import('./views/auth/admin/LoginPage.vue')
    },
    {
        path: "/admin/projects",
        name: "admin-projects",
        component: () => import('./views/admin/ProjectsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/projects/:id",
        name: "admin-project",
        component: () => import('./views/admin/ProjectPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/clients",
        name: "admin-clients",
        component: () => import('./views/admin/ClientsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/client/:id",
        name: "admin-client",
        component: () => import('./views/admin/ClientProfilePage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/experts",
        name: "admin-experts",
        component: () => import('./views/admin/ExpertsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/expert/:id",
        name: "admin-expert",
        component: () => import('./views/admin/ExpertProfilePage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/payouts",
        name: "admin-payouts",
        component: () => import('./views/admin/PayoutsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/questions",
        name: "admin-questions",
        component: () => import('./views/admin/QuestionsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/questions/:id",
        name: "admin-question",
        component: () => import('./views/admin/QuestionPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/transactions",
        name: "admin-transactions",
        component: () => import('./views/admin/TransactionsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }
    },
    {
        path: "/admin/settings",
        name: "admin-settings",
        component: () => import('./views/admin/SettingsPage.vue'),
        meta: {
            authRequired: true,
            isAdmin: true,
        }

    },

    /**
     * ADMIN AUTH
     */

    {
        path: "/admin/login",
        name: "admin-login",
        component: () => import('./views/auth/admin/LoginPage.vue')
    },

    /*
    {
        path: "/admin/payouts",
        name: "admin-payouts",
        component: () => import('./views/admin/PayoutsPage.vue')
    },
    {
        path: "/admin/project-ideas",
        name: "admin-project-ideas",
        component: () => import('./views/admin/ProjectIdeasPage.vue')
    },
    {
        path: "/admin/questions",
        name: "admin-questions",
        component: () => import('./views/admin/QuestionsPage.vue')
    },*/

    /**
     * CLIENT DASHBOARD
     */

    {
        path: "/client",
        name: "client-dashboard",
        component: () => import('./views/client/DashboardPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/project/:id",
        name: "client-project",
        component: () => import('./views/client/ProjectPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/pricing",
        name: "client-pricing",
        component: () => import('./views/client/PricingPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/settings",
        name: "client-settings",
        component: () => import('./views/client/SettingsPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/team",
        name: "client-team",
        component: () => import('./views/client/TeamPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/project-ideas",
        name: "client-project-ideas",
        component: () => import('./views/client/ProjectIdeas.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/questions",
        name: "client-questions",
        component: () => import('./views/client/QuestionsPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },
    {
        path: "/client/transactions",
        name: "old-client-transactions",
        component: () => import('./views/client/TransactionsPage.vue'),
        meta: {
            authRequired: true,
            isClient: true,
        }
    },


    {
        path: "/client/login",
        name: "client-login",
        component: () => import('./views/auth/client/LoginPage.vue')
    },
    {
        path: "/client/register",
        name: "client-register",
        component: () => import('./views/auth/client/RegisterPage.vue')
    },
    {
        path: "/client/new-project",
        name: "client-new-project",
        component: () => import('./views/auth/client/NewProjectPage.vue')
    },
    {
        path: "/client/forgot-password",
        name: "client-forgot-password",
        component: () => import('./views/auth/client/ForgotPasswordPage.vue')
    },

    /*
    {
        path: "/client/team",
        name: "old-client-team",
        component: () => import('./views/client/old/TeamPage.vue')
    },
    {
        path: "/client/team/full",
        name: "old-client-team-full",
        component: () => import('./views/client/old/TeamPage2.vue')
    },
    {
        path: "/client/referrals",
        name: "old-client-referrals",
        component: () => import('./views/client/old/ReferPage.vue')
    },
    {
        path: "/client/transactions",
        name: "old-client-transactions",
        component: () => import('./views/client/old/TransactionsPage.vue')
    },
    {
        path: "/client/project-ideas-a",
        name: "old-client-project-ideas-a",
        component: () => import('./views/client/old/ProjectIdeasA.vue')
    },
    {
        path: "/client/project-ideas-b",
        name: "old-client-project-ideas-b",
        component: () => import('./views/client/old/ProjectIdeasB.vue')
    },
    */

    /**
     * EXPERT DASHBOARD
     */

    {
        path: "/expert",
        name: "expert-dashboard",
        component: () => import('./views/expert/DashboardPage.vue'),
        meta: {
            authRequired: true,
            isExpert: true,
        }
    },
    {
        path: "/expert/available",
        name: "expert-available",
        component: () => import('./views/expert/AvailablePage.vue'),
        meta: {
            authRequired: true,
            isExpert: true,
        }
    },
    {
        path: "/expert/project/:id",
        name: "expert-project",
        component: () => import('./views/expert/ProjectPage.vue'),
        meta: {
            authRequired: true,
            isExpert: true,
        }
    },
    {
      path: "/expert/questions",
      name: "expert-questions",
      component: () => import('./views/expert/QuestionsPage.vue'),
      meta: {
        authRequired: true,
        isExpert: true,
      }
    },
    {
        path: "/expert/payouts",
        name: "expert-payouts",
        component: () => import('./views/expert/PayoutsPage.vue'),
        meta: {
            authRequired: true,
            isExpert: true,
        }
    },
    {
        path: "/expert/settings",
        name: "expert-settings",
        component: () => import('./views/expert/SettingsPage.vue'),
        meta: {
            authRequired: true,
            isExpert: true,
        }
    },{
        path: "/expert/profile",
        name: "expert-profile",
        component: () => import('./views/expert/ProfilePage.vue'),
        meta: {
            authRequired: true,
            isExpert: true,
        }
    },

    /**
     * EXPERT AUTH
     */

    {
        path: "/expert/login",
        name: "expert-login",
        component: () => import('./views/auth/expert/LoginPage.vue')
    },
    {
        path: "/expert/register",
        name: "expert-register",
        component: () => import('./views/auth/expert/RegisterPage.vue')
    },
    {
        path: "/expert/forgot-password",
        name: "expert-forgot-password",
        component: () => import('./views/auth/expert/ForgotPasswordPage.vue')
    },

    /*
    {
        path: "/expert/team",
        name: "expert-team",
        component: () => import('./views/expert/old/TeamPage.vue')
    },
    {
        path: "/expert/team/full",
        name: "expert-team-full",
        component: () => import('./views/expert/old/TeamPage2.vue')
    },
    {
        path: "/expert/available/projects",
        name: "expert-available-projects",
        component: () => import('./views/expert/old/AvailableProjectsPage.vue')
    },
    {
        path: "/expert/referrals",
        name: "expert-referrals",
        component: () => import('./views/expert/old/ReferPage.vue')
    },
    */
]