import { createApp } from 'vue'
import PolarisVue from '@ownego/polaris-vue'
import '@ownego/polaris-vue/dist/style.css'

import router from "@/routes";
import App from './App.vue'
import axios from 'axios';
import InfiniteScrollDirective from './directives/v-infinite-scroll';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

let token = localStorage.getItem('CURRENT_TOKEN')

if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.get('api/auth-check').catch(() => {
    window.localStorage.removeItem('CURRENT_USER')
    window.localStorage.removeItem('CURRENT_TOKEN')

    delete axios.defaults.headers.common['Authorization'];
})


const app = createApp(App)

app.directive('infinite-scroll', InfiniteScrollDirective);

app.use(PolarisVue).use(router).mount('#app')